import axios, { InternalAxiosRequestConfig } from "axios";
import { useCallback, useMemo, useRef, useState } from "react";
import u, { ELocalKey } from "@/util"
import { EModalTypes, modalSubject$ } from "./useModal";
import { API, PROD_SITE, QUESTION_API } from "@/const/prod.const";
import useConfBase from "./useConfBase";


let cancelTokenSource = null as any;

export function cancelAllRequests() {
    if (cancelTokenSource) {
        cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource.cancel();
    }
}

axios.interceptors.request.use((config: InternalAxiosRequestConfig<any> & { __data?: any, __endpoint?: any }) => {
    //https://utt.51jiajiao.top/
    //https://mj.pki.net.cn/
    let isNeedSuffix = true;
    let isReqJson = false;
    let _domain = u.getLocalData(ELocalKey._BASE)?.config?.json_host;
    if (_domain) {
        _domain = _domain.endsWith('/') ? _domain : `${_domain}/`;
    } else {
        _domain = process.env.REACT_APP_STATIC_JSON_HOST
    }

    u.sendStatAjax(); // send stat API
    
    var time = new Date();
    if (time.getHours() < 4){
        time.setHours(0, 0, 0, 0)
    }
    else if (time.getHours() < 8){
        time.setHours(4, 0, 0, 0)
    }
    else if (time.getHours() < 12){
        time.setHours(8, 0, 0, 0)
    }
    else if (time.getHours() < 16){
        time.setHours(12, 0, 0, 0)
    }
    else if (time.getHours() < 20){
        time.setHours(16, 0, 0, 0)
    }
    else if (time.getHours() < 24){
        time.setHours(20, 0, 0, 0)
    }
    else{
        time.setHours(24, 0, 0, 0)
    }
    console.log("see sethour", time.getHours())
    console.log("see sethour", time.setHours(16, 0, 0, 0))


    // let ts = time.getTime();
    let ts = '';

    const val = _domain;
    if (!val) return config;
    let _url = `/${config?.__endpoint}`;
    if (_url && _url.indexOf("index/countbanner") >= 0) {
        isNeedSuffix = false;
        isReqJson = true;
    }
    if (_url && _url.indexOf("index/countads") >= 0) {
        isNeedSuffix = false;
        isReqJson = true;
    }
    if (_url && _url.indexOf("index/statistics") >= 0) {
        isNeedSuffix = false;
        isReqJson = true;
    }
    if (_url && _url.indexOf("index/home") >= 0) {
        config.url = `${val}data${_url}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("topic/list") >= 0) {
        config.url = `${val}data${_url}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("topic/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("config/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("notice/index") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("notice/list") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}-${config.__data.type}-${config.__data.is_mobile}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("enter/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("appdown/base") >= 0) {
        config.url = `${val}data${_url}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("appdown/detail") >= 0) {
        config.url = `${val}data${_url}-${config.__data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("category/base") >= 0) {
        config.url = `${val}data${_url}-${PROD_SITE}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("list/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}-${___data.name}-${___data.page}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("tupian/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("shipin/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("cili/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("meinv/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("xiaoshuo/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("yousheng/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("news/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("chapter/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}-${___data.id}-${___data.page}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("index/base") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("shipin/nvyou") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}-${___data.page}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("tese/detail") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}-${___data.page}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("tese/play") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("topic/play") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.id}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("video/random") >= 0) {
        const ___data = config.__data;
        config.url = `${val}data${_url}-${___data.channel}-${___data.tags}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (_url && _url.indexOf("video/randomLike") >= 0) {
        config.url = `${val}data${_url}.js?${ts}`;
        config.method = "get"
        isNeedSuffix = false;
    }
    if (isNeedSuffix) {
        config.headers['suffix'] = 123456;
    } else {
        delete config.headers['suffix'];
        config.headers['Content-Type'] = "text/plain";
    }

    if(isReqJson){
        config.headers['Content-Type'] = "application/json";
    }
    return config;
},
    (error) => {
        return Promise.reject(error);
    })

/**
 * @param baseUrlType 1 => 反馈接口
 */
const useAxios = (url: string, method: any, noEncrypt?: boolean, raw?: boolean, baseUrlType?: 1) => {
    const [error, setError] = useState("");
    const [config] = useConfBase()
    const [loaded, setLoaded] = useState(false);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
        controllerRef.current.abort();
    };

    const baseUrl = useMemo(() => {
        let baseUrl = API ?? '';
        // let baseUrl = //"https://site11api.beladns.com/api"
        const env = process.env.NODE_ENV
        if (env === "production") {
            baseUrl = u.getLocalData(ELocalKey._BASE)?.api_url || process.env.REACT_APP_STATIC_BASE_ISPROD
        }

        // used for index/statistics only
        if (noEncrypt) {
            if(!url.includes('index/countbanner') && !url.includes('index/countads')){
                // baseUrl = config.tongji_domain
                baseUrl = process.env.REACT_APP_STATIC_INDEX_STATIS ?? config.tongji_domain 
            }else{
                // baseUrl = config.tongji_domain
                baseUrl = process.env.REACT_APP_STATIC_INDEX_STATIS ?? config.tongji_domain
                baseUrl = baseUrl.replace('/index/statistics','')
            }
        }
        if (baseUrlType === 1) {
            baseUrl = QUESTION_API ?? ''
        }
        return baseUrl
    }, [noEncrypt, baseUrlType])

    const req = useCallback(async (data?: any, cb?: any) => {
        delete axios.defaults.headers.common['Authorization'];
        let tme = null as any;
        let passPayload = {} as any
        if (noEncrypt) {
            passPayload["device"] = u.isMobile() ? 'mobile' : 'pc'
        } else {
            passPayload["system"] = u.isMobile() ? 2 : 1;
            passPayload["timestamp"] = new Date().getTime();
            passPayload["device"] = u.isMobile() ? 'mobile' : 'pc'
        }
        if (!noEncrypt) {
            passPayload = { ...passPayload, ...data }
            console.log(url, 'passPayload', passPayload)
            passPayload["encode_sign"] = u.base64Sign(passPayload);
            passPayload = { "post-data": u.encrypt(JSON.stringify(passPayload)) };
        }
        try {
            let callURL = `${baseUrl}/${url}`;
            if(baseUrl.includes('index/statistics')){
                passPayload = { ...passPayload, ...data }
                passPayload["sign"] = u.base64SignWithKey(passPayload, "qWtIrTFmjoEqaUbOxf0M");
                callURL = baseUrl;
            }

            if(url.includes('index/countbanner') || url.includes('index/countads')){
                passPayload = { ...passPayload, ...data }
                passPayload["sign"] = u.base64SignWithKey(passPayload, "qWtIrTFmjoEqaUbOxf0M");
            }
            const conbimeData = { ...passPayload, ...data }
            const response = await axios.request({
                // timeout: 3000,
                data: noEncrypt ? conbimeData : passPayload,
                method,
                signal: controllerRef.current.signal,
                url: callURL,
                __data: conbimeData, // not encrpted data
                __endpoint: url, // pass url
            } as any);
            let __data = u.decrypt(response.data.data, response.data.suffix);
            __data = JSON.parse(__data)
            response.data.data = __data;

            // console.log(url, ' response -> ', __data)
            if (__data.yimi_statistic_domain) {
                u.setStatisticDomain(__data.yimi_statistic_domain);
            }

            if (raw) {
                response.data["_raw"] = {
                    status: response.status
                }
            }
            cb && cb()
            return response.data;
        } catch (error: any) {
            setError(error.message);
            // json 添加弹窗
            if (url == "config/base") {
                modalSubject$.next({
                    id: EModalTypes.NORMAL,
                    innerTitle: "链接失败，请稍后再试！",
                    btn: true,
                })
            }
            cb && cb()
        } finally {
            setLoaded(true);
        }
    }, [url, method, noEncrypt, raw, baseUrl])

    return useMemo(() => ({ cancel, error, loaded, req }), [cancel, error, loaded, req]);
};
export default useAxios;