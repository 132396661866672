import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Img from "./img";
import { appendHost } from "@/const/append";

import style from "@/components/styles/header.module.css";
import { Link, useNavigate } from "react-router-dom";
import useUser from "@/hooks/useUser";
import useAxios from "@/hooks/useAxios";
import VipCard, { vipCardSubject$ } from "@/components/vipCard";
import useConfBase from "@/hooks/useConfBase";
import u, { ELocalKey, EVIPtype, teseConstant } from "@/util";
import useWindowLoadCallback from "@/hooks/useWindowEffect";
import useCategory from "@/hooks/useCategory";
import useWindowDimensions from "@/hooks/useDimension";
import { categpryImg } from "./category";

interface IHeader {
    isScroll: boolean;
    hideHead?: boolean
}

const Header: FC<IHeader> = ({ isScroll, hideHead }) => {
    const [config, updateConfBase, getData] = useConfBase();
    const [userInfo] = useUser();
    const [selected, setSelected] = useState<any>(0);
    const [clickVipCard, setVipCard] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false)
    const [downloadUrl, setDownloadUrl] = useState<string>("");
    const [dlist, setDlist] = useState<any>({})
    const { req } = useAxios("index/statistics", "post", true);
    const { req: reqUnseenCount } = useAxios("message/unseenCount", "post");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [caterList, setCaterList] = useState<any[]>([])
    const [defData, setDefData] = useState<any>([])
    const [zhutiData, setZhutiData] = useState<any>([])
    const category = useCategory()
    const { width } = useWindowDimensions();
    const navigate = useNavigate;
    const [indexShowSubmenu, setIndexShowSubmenu] = useState<any>(-1)
    const [msgAmt, setMsgAmt] = useState<any>(0)

    const handleIndexSubMenu = (index: number) => {
        if(indexShowSubmenu === index){
            setIndexShowSubmenu(-1);
            return;
        }
        setIndexShowSubmenu(index);
      };

    const handleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
      };

      const cilihandler = useCallback(async (type: any, realName: any, channel: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname);
        // req({ ...__val, category: "site", name: type ? "pic" : "cili" });
        let categoryToSend = type ? channel : "cili"
        req({ ...__val, category: categoryToSend , name: realName});

        if (process.env.NODE_ENV === "production") {
            const val = type ? "pic." : "download."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            return u.open(`https://${urlary}`)
        }
        return u.open(type ? config?.pic_url : config?.cili_url)
    }, [])

    const goHitAllStats = useCallback(async (val: any, jumpUrl?: string) => {
        console.log(val)
        const __val = u.getStatsParams(false, val?.channel, val?.name, window.location.pathname,) as any;
        if (typeof val?.index === "number" && __val?.c) {
            __val["c"] = __val["c"] + val?.index + 1
        }
        req({ ...__val });
        // const token = u.getLocalData(ELocalKey.__QTKN, true);
        // if (!token) return;
        // const __tqknParams = {
        //     token,
        //     project_id: ProjectInfo.ID,
        //     site: __val.site,
        //     page: __val?.name,
        //     category: val?.channel,
        //     type: "link" as any,
        //     domain: __val.domain
        // }
        // R.request(postQesStats(__tqknParams), false, false, 1);
        if (jumpUrl) {
            return u.open(jumpUrl)
        }
    }, [])
      
    const handleAuthVIP = useCallback(async (val: any, type?: any, index?:any) => {
        console.log('site_cili', config?.site_cili)
        console.log('val', val)
        console.log('type', type)

        if(val.channel === 'topic'){
            return goHitAllStats(val, val.url)
        }
        // xiaoshuo add novel.
        if(val.channel === 'xiaoshuo' && config?.site_xiaoshuo && config?.site_xiaoshuo === '1' && process.env.NODE_ENV === "production"){
            const val = "novel."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            await goHitAllStats(val)
            return u.open(`https://${urlary}`)
        }

        if((val.id === 149 || val.id === 158  || val.id === 159)  && val.channel === 'remen'){
            return; // temp unable click
        }

        if(config?.site_cili == 1) {
            if(val.channel == 'cili') {
                cilihandler(0, val.name, val.channel);
                return;
            } else if(val.channel == 'meinv' || val.channel == 'tupian') {
                cilihandler(1, val.name, val.channel);
                return;
            }
        }
        // 概率跳转
        const isMatch = Math.floor(Math.random() * 4) === 3;
        const res = u.percentJump(val?.id);
        if (isMatch && res) {
            return goHitAllStats(val, res)
        }
        if (val?.exception) {
            // 根据拿到的id判断是否要跳到tese页面
            await goHitAllStats(val)
            if (typeof val?.actualId === 'number' && val?.actualId > 0) {
                return window.location.href = `/page/tese/${val?.actualId}` // u.open(`/page/tese/${val?.actualId}`)
            }
            return u.open(val?.url)
        }
        if (val?.channel && val?.id) {
            goHitAllStats(val)
            if (/^(http|https):\/\//.test(val?.url)) {
                sessionStorage.setItem("z", val?.name)
                sessionStorage.setItem("n", val?.url)
                return u.open("/external")
            }
            // cancelAllRequests()
            return u.mapCategoryRoute(navigate, type, val.channel, val.id, userInfo?.token)
        }
    }, [userInfo, navigate])

      useEffect(() => {
        const intervalQX = setInterval(qxData, 1000);

        function qxData() {
            const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
            console.log('qxData run this' , __data)
            if (Array.isArray(__data?.["zhuanti"])) {
                setZhutiData(__data["zhuanti"])
            }
            console.log('qxData' ,  zhutiData)
            if(__data){
                stopQX();
            }
        }
    
        function stopQX() {
         console.log('stopQX header', zhutiData)
         clearInterval(intervalQX);
        }
        // u.timeoutCb(() => {
        //     const __data  = u.getStringtifyVal(localStorage.getItem(ELocalKey.__QX));
        //     console.log('run this' , __data)
        //     if (Array.isArray(__data?.["zhuanti"])) {
        //         setZhutiData(__data["zhuanti"])
        //     }
        // });

        if(!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === ''){
            // 在hooks里一直被使用，需要锁
        getData(4).then((res: any) => {
                                localStorage.setItem(ELocalKey._LOCK_FORMAT, '1'); 
                if (Array.isArray(res?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(res["tesezhuanqu"][0]["child"])
                }

                if (Array.isArray(res?.["zhuanti"])) {
                    setZhutiData(res["zhuanti"])
                }
            })
        }else{
            if(localStorage.getItem(ELocalKey._FORMAT)){
                const data =JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                if (Array.isArray(data?.["tesezhuanqu"]?.[0]?.["child"])) {
                    setDefData(data["tesezhuanqu"][0]["child"])
                }

                try{
                    if (Array.isArray(data?.["zhuanti"])) {
                        setZhutiData(data["zhuanti"])
                    }
                }catch(err){}
            }
        }
    }, [config])

      useEffect(() => {
        if(defData && defData.length === 0){
            setDefData(teseConstant)
        }
        const _list = [...category];
        console.log(defData, 'sidebar')
        console.log(zhutiData, 'zhutiData')

        // def Data
        const __ext = defData.map((x: any) => ({
            ...x,
            name: x?.name,
            url: x.url,
            id: 1,
            channel: "tesezhuanqu",
            exception: true,
            actualId: x?.provider_id || 0
        }))
        const _val = { icon: categpryImg[1], name: "特色专区", data: __ext }

         // zhutiData
         const __ext2 = zhutiData.map((x: any) => ({
            ...x,
            name: x?.name,
            url: "/page/topic/" + x.id,
            id: x.id,
            channel: "topic",
            exception: true,
            actualId: x?.provider_id || 0
        }))
        const _val2 = { icon: categpryImg[0], name: "限时专题", data: __ext2, url: "/topic", channel: "topic" }
        console.log('zhaunti' , _val2);

        if (width < 600) {
            _list.push( _val)
            // _list.splice(1, 0, _val)
            _list.splice(1, 0, _val2)
            console.log('_list push head 1' , _list);
        } else {
            _list.push( _val)
            // _list.splice(1, 0, _val)
            _list.splice(1, 0, _val2)
            console.log('_list push head 2' , _list);
        }
        return setCaterList(_list)
    }, [category, defData, zhutiData, width])

    const filterCater = useCallback((data: any[]) => {
        const normalHideIds = [66]
        const mustHideIds = [183]
        data = data?.filter((x) => !mustHideIds.includes(x.id))

        return data.filter((x) => !normalHideIds.includes(x.id) && !normalHideIds.includes(x.parent_id))
    }, [])

    const fetchMsgAmt = useCallback(async () => {
        if (!userInfo?.token) return;
        const res = await reqUnseenCount({
            token: userInfo?.token
        });

        console.log('reqUnseenCount message  ', res)
        if (res) {
            setMsgAmt(res?.data?.unseen_count)
        }
    }, [userInfo])


    useEffect(() => {
        fetchMsgAmt()
    }, [fetchMsgAmt])
    // useEffect(() => {
    //     getData(1).then((res: any) => {
    //         if (res?.maomiapk?.url) {
    //             setDownloadUrl(res?.maomiapk?.url)
    //         }
    //     })
    // }, [getData, config])

    // useWindowLoadCallback(() => {
    //     const res = u.getScriptVal(1) as any;
    //     if (res?.maomiapk?.url) {
    //         setDownloadUrl(res?.maomiapk?.url);
    //     }
    // })

    useEffect(() => {
        u.timeoutCb(() => {
            const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
            if (__data && __data?.maomiapk?.url) {
                setDownloadUrl(__data.maomiapk.url);
            }
        })
    }, [])

    useEffect(() => {
        if(!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === ''){
            // 在hooks里一直被使用，需要锁
        getData(4).then((res: any) => {
                                if (res) {
                    localStorage.setItem(ELocalKey._LOCK_FORMAT, '1'); 
                    setDlist(res)
                }
            })
        }else{
            if(localStorage.getItem(ELocalKey._FORMAT)){
                const data =JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                setDlist(data)
            }
        }
    }, [getData])

    const hitStats = useCallback(async (x: any) => {
        req(u.getStatsParams(true, x?.channel, `${x?.symbol}${x?.name}`, x?.name, x?.symbol === "yq_")) // header bar
        if (typeof x?.provider_id === "number" && x?.provider_id > 0) {
            return u.open(`/page/tese/${x?.provider_id}`)
        } else {
            sessionStorage.setItem("z", x.name)
            sessionStorage.setItem("n", x.url)
            u.open("/external")
        }
    }, [])

    const hitStats1 = useCallback(async (x: any,) => {
        if (x?.url) {
            const __val = u.getStatsParams(true, x?.channel, x?.name, x?.url) as any;

            if (__val?.c && x?.count) {
                __val["c"] = __val["c"] + x?.count;
            }
            req(__val)
            return u.open(x.url)
        }
    }, [])

    
    const hitStats2 = useCallback(async () => {
        // if (x?.url) {
            const __val = u.getStatsParams(false, "news", "每日发现", "") as any;

            // if (__val?.c && x?.count) {
            //     __val["c"] = __val["c"] + x?.count;
            // }
            req(__val)

            const val = "luntan."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            return u.open(`https://${urlary}/bbs`);
            // return u.open("/news")
        // }
    }, [])


    const clickDl = useCallback(async () => {
        const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
        if (__data && __data?.maomiapk?.url) {
            window.open(__data.maomiapk.url)
        }
    }, [])


    const cssStyle = useMemo(() => {
        const _style = `fl ${style.header} align_center justify_center ${u.themeHeader()}`
        return isScroll ? `${_style} ${style.scroll}` : _style
    }, [isScroll])

    return (
        <div className={`relative ${hideHead ? 'hideh' : ''}`}>
            <div className={cssStyle}>
                <div className={`grid ${style.inner} align_center`}>
                    <div className={`fl gap10 align_ceter ${style.lg1}`}>
                        {u.isMobile() && 
                          <div onClick={handleSidebar}>
                            <Img height={17} width={23} src={"/images/header/menu_burger.png"} />
                            </div>
                        }
                        <Link to="/main">
                            <Img cls={style.logo_1} width={130} height={25} src={"/images/header/logo1.png"} />
                        </Link>
                        <div className={style.lct}>
                            {window.location.host}
                        </div>
                    </div>
                    <div className={`fl align_center gap10 mauto ${style.lg2}`}>
                        <div className="fl gap5 align_center">
                            <Img height={15} width={15} src={"/images/header/menu_home.png"} />
                            <Link className="a_w" to="/main">
                                <div>首页</div>
                            </Link>
                        </div>
                        <div>/</div>
                        <div className="fl gap5 align_center">
                            <Img height={15} width={15} src={"/images/header/menu_new.png"} />
                            <div className="point" onClick={() => hitStats2()}>每日发现</div>
                        </div>
                    </div>
                    <div className={`fl align_center ${style.ti_con}`}>
                        {topSectionList(dlist).map((x, i) => {
                            return <div key={i} className={`fl point gap5 relative ${style.h40} ${selected === i && selected >= 3 ? style.bl : ''}`} onMouseEnter={() => setSelected(i)} onMouseLeave={() => setSelected(0)} >
                                <div className={style.lh54} onClick={() => {
                                    hitStats1({ ...x, index: i })
                                }}>
                                    {x.name}
                                </div>
                                <div className={style.mt25}>
                                    {Array.isArray(x.child) && x.child.length > 0 && <span className={style.dropd}></span>}
                                </div>
                                {selected === i && selected >= 3 && <div className={`abs ${style.top_item}`}>
                                    <div onMouseLeave={() => setSelected(0)}>
                                        {x.child?.map((t: any, i: number) => {
                                            return <div key={i} onClick={() => hitStats({ ...t, symbol: x?.code })}>
                                                {t.name}
                                            </div>
                                        })}
                                    </div>
                                </div>}
                            </div>
                        })}
                    </div>
                    <div className={style.h_btm_con}>
                        <div onClick={() => setShow(!show)} className={`${style.lg3} relative`}>
                            <div>
                                {!!msgAmt && msgAmt > 0 && <>
                                    <div className={style.msgamt}>{msgAmt}</div>
                                </>}
                                <Img src={"/images/header/user_logo.png"} width={40} height={28} />
                            </div>
                            {show && <div className={`abs ${style.ho_m}`}>
                                <div className={style.line} >
                                    <Img src={"/images/header/icon_avatar.png"} width={20} height={20} />
                                    <div>
                                        {userInfo?.token ? <div onClick={() => window.open("/user/info")}> {userInfo?.username} </div> : <>
                                            <span onClick={() => u.formLogin()}>登录</span>｜<span onClick={() => u.formRegister()}>注册</span>
                                        </>}
                                    </div>
                                </div>
                                <div className={style.line} onClick={() => setVipCard(true)}>
                                    <Img src={"/images/header/icon_vip.png"} width={20} height={20} />
                                    <div>VIP 福利</div>
                                </div>
                                <div className={style.line}>
                                    <Img src={"/images/header/icon_customer.png"} width={20} height={15} />
                                    <div>
                                        <a className="a_b" href="https://t.me/maomiresource">猫友开车群</a></div>
                                </div>
                                <div className={style.line} onClick={() => clickDl()}>
                                    <Img src={"/images/header/android.png"} width={12} height={20} />
                                    <div>APP下载</div>
                                </div>
                            </div>}
                        </div>
                        <div className={`fl align_center justify_end gap10 ${style.lg3_t}`}>
                            <div className="mt10" onClick={() => clickDl()}>
                                <Img height={30} width={70} src={"/images/header/app_btn.png"} />
                            </div>
                            <div className="mt10" onClick={() => window.open("/user/info")}>
                                <Img height={20} width={20} src={"/images/header/icon_vip.png"} />
                            </div>
                            <Link className="a_w mt9" to="/user/info" style={{position:'relative'}}>
                                <Img height={20} width={20} src={"/images/header/icon_avatar.png"} />
                                {!!msgAmt && msgAmt > 0 && <>
                                    <div className={style.msgamtdesktop}>{msgAmt}</div>
                                    </>}
                            </Link>
                            {userInfo?.token ?
                                <div className="fz12 point text_over">
                                    <Link className="a_w" to="/user/info">
                                        {userInfo?.username}
                                    </Link>
                                </div>
                                :
                                <div className="fl align_center fz12 gap10 ">
                                    <Link className="a_w" to={u.formLogin(true)}>登录</Link>
                                    <div>|</div>
                                    <Link className="a_w" to={u.formRegister(true)}>注册</Link>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {clickVipCard && <VipCard closeEvent={() => {
                setVipCard(false);
                vipCardSubject$.next(true)
            }} isMobile />}

            {sidebarOpen && <>
                <div id="mySidenav" className="sidenav" >
                    <div className="sideclose" onClick={handleSidebar}>
                      
                    </div>
                    <div className="sidecontent">
                        <div className="sidelogo">
                            <Img width={130} height={25} src={"/images/header/logo_pink.png"} />
                        </div>


                        <div className="mainmenu" onClick={() => hitStats2()}>
                                    <div className="mainmenucontent" style={{display: 'flex'}} >
                                        <Img width={40} height={31} src={"/images/menu/home_icon_jinrifaxian.png"} 
                                        />
                                        每日发现
                                    </div>
                                 
                                    <div style={{display: 'flex', width: '50%', placeContent: 'flex-end'}}>
                                            <Img width={15} height={15} src={"/images/header/arrow_right.png"} />
                                    </div>
                        </div>

                        {filterCater(caterList).map((x: any, i: number) =>{
                            return <>
                                <div className="mainmenu">
                                    <div className="mainmenucontent" style={{display: 'flex'}} onClick={() => handleAuthVIP({ ...x, index: -1 }, 1, i)}>
                                        {x.name === '在线电影' && <>
                                        <Img width={40} height={31} src={categpryImg[5]} />
                                        </>}
                                        {x.name === '情色小说' && <>
                                        <Img width={40} height={31} src={categpryImg[6]} />
                                        </>}
                                        {x.name === '有声小说' && <>
                                        <Img width={40} height={31} src={categpryImg[7]} />
                                        </>}
                                        {x.name === '撸撸图区' && <>
                                        <Img width={40} height={31} src={categpryImg[1]} />
                                        </>}
                                        {x.name === '激情图区' && <>
                                        <Img width={40} height={31} src={categpryImg[4]} />
                                        </>}
                                        {x.name === '手机下载' && <>
                                        <Img width={40} height={31} src={categpryImg[3]} />
                                        </>}
                                        {x.name === '站站推荐' && <>
                                            <Img width={40} height={31} src={categpryImg[8]} />
                                        </>}
                                        {x.name === '另类色情' && <>
                                        <Img width={40} height={31} src={categpryImg[9]} />
                                        </>}
                                        {(i < 12 && x.name !== '站站推荐' && x.name !== '另类色情' && x.name !== '手机下载'
                                            && x.name !== '在线电影'  && x.name !== '情色小说'  && x.name !== '有声小说' 
                                            && x.name !== '撸撸图区' && x.name !== '激情图区'
                                        ) && <>
                                            <Img width={40} height={31} src={categpryImg[i]} />
                                        </>}
                                        {i >= 12 && <>
                                            <Img width={40} height={31} src={categpryImg[1]} />
                                        </>}
                                        {x.name}
                                    </div>
                                 
                                    <div style={{display: 'flex', width: '50%', placeContent: 'flex-end'}}
                                    onClick={()=>(handleIndexSubMenu(i))}>
                                        {indexShowSubmenu === i && <>
                                            <Img width={15} height={15} src={"/images/header/arrow_top.png"} />
                                        </>}
                                        {indexShowSubmenu !== i && <>
                                            <Img width={15} height={15} src={"/images/header/arrow_right.png"} />
                                        </>}
                                    </div>
                                </div>
                                {indexShowSubmenu === i && <>
                                    <div className="submenuwrapper">
                                    {Array.isArray(x.data) && x.data.map((x: any, v: number) => {
                                        return <div key={v} className="submenu fl1 point">
                                            <div onClick={() => handleAuthVIP({ ...x, index: v })} >{x.name}</div>
                                        </div>
                                    })}
                                </div>                               
                                </>}
                            </>
                        })}
                    </div>
                </div>
            </>}
        </div>
    )
}
export default Header;

const topSectionList = (data: any) => [
    {
        channel: "shipin",
        name: "中文字幕",
        url: "/page/shipin/11",
        count: 4,
    },
    {
        channel: "shipin",
        name: "国产精品",
        url: "/page/shipin/10",
        count: 3
    },
    {
        channel: "cili",
        name: "岛国无码",
        url: "/page/cili/143",
        count: 3
    },
    {
        code: "ts_",
        name: "特色专区",
        child: data?.["tesezhuanqu"]?.[0]?.child || [],
        symbol: "ts_"
    },
    {
        code: "yq_",
        name: "友情推荐",
        child: data["maomiqixia"],
        symbol: "yq_"
    },
]