import React, { FC, useCallback, useEffect, useState } from "react";
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import u, { EVIPtype } from "@/util";
import { Subject } from "rxjs";
import useUser from "@/hooks/useUser";
import styles from "@/components/styles/vipCard.module.css"
import modalStyles from "@/components/styles/modal.module.css"

export const vipCardSubject$ = new Subject()

interface IVipCard {
    isMobile?: boolean;
    closeEvent?: () => void
}


const VipCard: FC<IVipCard> = ({ isMobile, closeEvent }) => {
    const [userInfo] = useUser();
    const [time, setTime] = useState<string>("24:00:00")
    const [isSetMobile, setIsSetMbile] = useState<boolean>(false);

    useEffect(() => {
        if (typeof isMobile === 'boolean') {
            setIsSetMbile(isMobile)
        }
    }, [isMobile])

    const handleRoute = useCallback((path: string) => {
        if (u.isLogin()) {
            return window.open(path)
        }
        return u.formLogin()
    }, [])

    const handleCloseMobile = useCallback(() => {
        setIsSetMbile(false);
        return closeEvent && closeEvent()
    }, [closeEvent])

    const handleKf = () => {
        if (u.isLogin()) {
            return window.open(userInfo?.custom_url)
        }
        return u.formLogin()
    }

    useEffect(() => {
        vipCardSubject$.subscribe((res: any) => {
            if (typeof res === "boolean") {
                setIsSetMbile(res)
            }
        })
    }, [])

    useEffect(() => {
        u.countdown(setTime)
      }, [])

    return (
        <div className={`relative ${isSetMobile ? 'mask' : ''}`}>
            <div className={isSetMobile ? styles.head_vip_m_c : ''}>
                <Img height={388} width={174} src={appendHost("/images/user-center/info/vip-card-bg.png")} />
                <div className={`abs ${styles.wrapper}`}>
                    <div className={styles.pri}>{time}</div>
                    <div className={`grid ${styles.vipc}`}>
                        {item.map((x, i) => {
                            return <div key={i}>
                                <Img src={x.img} width={30} height={30} />
                                <div>{x.name}</div>
                            </div>
                        })}
                    </div>
                    <div className={`abs ${styles.btm}`}>
                        <div className={styles.left} onClick={() => handleRoute('/user/buy')}>点击获取</div>
                        <div className={styles.right} onClick={() => handleKf()}>在线客服</div>
                    </div>
                </div>
            </div>
            {isSetMobile && <div onClick={handleCloseMobile} className={`${modalStyles.close} ${styles.cbtn}`}>X</div>}
        </div>
    )
}
export default VipCard;

const item = [
    {
        name: "无限观看",
        img: appendHost("/images/user-center/info/vip_wxgk.png")
    },
    {
        name: "高清线路",
        img: appendHost("/images/user-center/info/vip_gqxl.png")
    },
    {
        name: "专属客服",
        img: appendHost("/images/user-center/info/vip_zskf.png")
    },
    {
        name: "没有广告",
        img: appendHost("/images/user-center/info/vip_mygg.png")
    },
    {
        name: "私人定制",
        img: appendHost("/images/user-center/info/vip_srdz.png")
    },
    {
        name: "VIP专区",
        img: appendHost("/images/user-center/info/vip_zq.png")
    },
]